/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}


.page-container {
  padding: 20px;
}

.page-header {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
}

.page-icon {
  font-size: 24px;
  /* Adjust icon size */
  margin-right: 5px;
  /* Space between icon and text */
}

h2 {
  padding: 0%;
  margin: 0%;
}

.btn-primary {
  background-color: #00A8E2;
}

.cursor-pointer {
  cursor: pointer;
}
